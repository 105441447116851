<template>
	<div>
		<m1 :mdata="obserdata"></m1>
	</div>
</template>

<script>
	import m1 from '@/components/common_components/m1.vue'
	
	export default {
		components: {
			m1
		},
		data() {
			return {
				obserdata: []
			}
		},
		created() {
			this.$post("/industry/categoryall",{category:"行业观察"}).then(resp=>{
				this.obserdata = resp.dataList
			})
		}
	}
</script>

<style>
	
</style>
